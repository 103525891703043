<!-- 收件箱 -->
<template>
  <div class="outbox-page" v-if="myEmailInfo">
    <van-cell class="title-box">
      <p class="title">
        收件箱<span class="red">({{ total }})</span>
      </p>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="(item, i) in list" :key="i">
          <div class="cell-cotent">
            <div class="star-left">
              <van-icon
                v-if="item.isStar == 1"
                @click="handleCancelStar(item)"
                name="star"
                class="icon on"
              />
              <van-icon
                v-if="item.isStar == 0"
                @click="addStar(item)"
                name="star-o"
                class="icon"
              />
            </div>
            <div class="right-content" @click="getoDetail(item)">
              <div class="top-time">
                <p
                  class="title"
                  v-for="(mail, m) in item.targetEmails"
                  :key="m"
                >
                  <span class="red" style="color: red" v-if="item.isRead == '0'"
                    >·</span
                  >
                  &lt;{{ mail.email }}&gt;
                </p>
                <p class="time">{{ item.emailTime | formatCalendar }}</p>
              </div>
              <div class="subject">
                <van-icon
                  v-if="item.hasAttachment"
                  name="link-o"
                  class="link"
                />

                {{ item.subject }}
              </div>
              <div class="content">{{ item.content }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
  <div class="outbox-page" v-else>
    <el-result icon="warning" title="警告提示" subTitle="请根据提示进行操作">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="goSet"
          >设置邮箱</el-button
        >
      </template>
    </el-result>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapActions, mapState } from "vuex";
import { getRecievePageList, handleStar, cancleStar } from "@/api/email";
export default {
  data() {
    return {
      total: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },

  components: {},

  computed: { ...mapState("email", ["myEmailInfo"]) },

  mounted() {
    this.getEmailInfo();
  },

  methods: {
    ...mapActions("email", ["getEmailInfo"]),
    goSet() {
      this.$router.push("/EmailSetting");
    },
    onLoad() {
      this.getList();
    },
    getList() {
      getRecievePageList(this.listQuery)
        .then((res) => {
          this.refreshing = false;
          if (res) {
            let { data, code } = res;
            if (code == 200 && data) {
              this.total = data.total;
              this.list = this.list.concat(data.records);
              if (this.list.length > this.total) {
                this.finished = true;
              } else {
                if (this.listQuery.pageNum >= data.pages) {
                  this.finished = true;
                } else {
                  this.listQuery.pageNum++;
                }
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      if (this.list.length < this.total) {
        this.finished = false; //清空类表数据
        this.loading = true; //加载状态
        this.getList();
      } else {
        this.loading = false;
        this.refreshing = false;
      }
    },
    getoDetail(row) {
      this.$router.push({
        path: "/harvestDetail",
        query: { isSender: row.isSender, unid: row.unid },
      });
    },

    addStar(item) {
      handleStar({ unid: item.unid, isSender: item.isSender }).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            this.listQuery.pageNum = 1;
            this.list = [];
            this.onRefresh();
            Toast(msg);
          }
        }
      });
    },
    handleCancelStar(item) {
      cancleStar({ idCode: item.idCode }).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            this.listQuery.pageNum = 1;
            this.list = [];
            this.onRefresh();
            Toast(msg);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.outbox-page {
  width: 100%;
  min-height: 100%;
  .title-box {
    background: #f0f6ff;
    .title {
      text-align: center;
      .red {
        color: red;
      }
    }
  }
  .cell-cotent {
    display: flex;
    flex-direction: row;
    width: 100%;
    .star-left {
      width: 0.4rem;
    }
    .right-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      .top-time {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
          color: #000;
          font-weight: 900;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .time {
          width: 3.3rem;
          text-align: right;
          color: #999;
        }
      }
      .subject {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999;
        font-size: 0.2rem;
      }
    }

    .link {
      color: #0094ee;
    }
  }
  ::v-deep .van-cell::after {
    border-bottom: 0.08rem solid #e8ecfc;
  }
  .on {
    color: gold;
  }
}
</style>
